<template>
    <div>
        <AMInAppAlerts />

        <KCHeader
            :user="user"
            @logout="logout"
            @reset-password="resetPassword"
        >
            <h1 class="header-title">
                Service Tracker
            </h1>
        </KCHeader>

        <STSearch />

        <STSearchResultsTable />
    </div>
</template>

<script>
    import AMInAppAlerts from '@imt/vue-admin-menus/src/components/AMInAppAlerts.vue';
    import KCHeader from '@imt/vue-kit-car/src/components/layout/Header.vue';
    import {mapActions, mapState} from 'vuex';

    import STSearch from '@/components/STSearch.vue';
    import STSearchResultsTable from '@/components/STSearchResultsTable.vue';

    export default {
        name: 'STHome',
        components: {
            KCHeader,
            AMInAppAlerts,
            STSearch,
            STSearchResultsTable,
        },
        computed: {
            ...mapState('toolbox', [
                'user',
            ]),
        },
        methods: {
            ...mapActions([
                'logout',
                'resetPassword',
            ]),
        },
    };
</script>
