<template>
    <div class="container pt-5">
        <div
            class="row justify-content-center"
            data-cy="searchSelectionBox"
        >
            <div class="col-12 col-md-8 col-lg-6">
                <label>Record Type</label>

                <SelectionBox
                    v-model="recordType"
                    data-cy="recordTypeSelection"
                    :button-options="recordTypeOptions"
                    :read-only="loading"
                    @selection-box-input="toggleSearch"
                />
            </div>
        </div>

        <RouterView />
    </div>
</template>

<script>
    import SelectionBox from '@imt/vue-kit-car/src/components/SelectionBox.vue';
    import {mapGetters, mapMutations, mapState} from 'vuex';

    export default {
        name: 'STSearch',
        components: {
            SelectionBox,
        },
        data() {
            return {
                recordType: [this.$route.name.includes('service') ? 'service' : 'rating'],
            };
        },
        computed: {
            ...mapGetters(['recordTypeOptions']),
            ...mapState(['loading'])
        },
        methods: {
            toggleSearch() {
                this.SET_DATA({type: 'submitted', data: false});

                this.$router.push({
                    name: this.recordType,
                    query: {...this.$route.query, ['page']: '1'}
                });
            },
            ...mapMutations(['SET_DATA'])
        }
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/modules/_multiselect.sass"

    #companySelect .btn,
    #serviceSelect .btn,
    #vendorSelect .btn
        border-radius: initial

        &:not(:first-child)
            margin-left: 0
</style>
