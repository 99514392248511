<template>
    <div class="container-fluid">
        <div
            v-show="submitted && results.length"
            class="col-12"
            data-cy="resultsTable"
        >
            <p class="text-center font-weight-bold">
                Number of results returned: {{ count }}
            </p>

            <BTable
                ref="resultsTable"
                :current-page="searchFields.page"
                :fields="$route.name.includes('service') ? serviceFields : ratingFields"
                fixed
                hover
                :items="results"
                :per-page="0"
                :selectable="selectable"
                :select-mode="selectMode"
                selected-variant="primary"
                small
                striped
                @row-selected="onRowSelected"
                @sort-changed="returnToFirst"
            >
                <template #cell(date)="data">
                    {{ data.item.date ? date(data.item.date) : '' }}
                </template>
            </BTable>
        </div>

        <LFooter
            v-if="showPagination"
            id="resultsPagination"
            data-cy="resultsPagination"
            admin-key="resultsTable"
            :analytics-prefix="`Search Results`"
            :fetching="loading"
            :footer-only="true"
            :total-item-count="count"
            @fetch="pageChange"
        />
        <div
            v-if="loading"
            class="row justify-content-center"
        >
            <FontAwesomeIcon
                far
                icon="spinner"
                pulse
            />
        </div>
    </div>
</template>

<script>
    import LFooter from '@imt/vue-list/src/components/LFooter.vue';
    import toolboxFilters from '@imt/vue-toolbox/src/mixins/filters';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import {mapActions, mapMutations, mapGetters, mapState} from 'vuex';

    import searchMixin from '@/mixins/search';

    export default {
        name: 'STSearchResultsTable',
        components: {
            LFooter,
        },
        mixins: [
            searchMixin,
            toolboxFilters,
            toastsMixin
        ],
        data() {
            return {
                currentPage: 1,
                selectedCompany: '',
                selectedDate: '',
                selectedPayload: '',
                selectedResponse: '',
                selectedService: '',
                selectedVendor: '',
                selectMode: 'single',
            };
        },
        computed: {
            selectable() {
                return !!this.permissions.service_details;
            },
            showPagination() {
                return !this.loading && this.submitted && this.results.length && !this.$route.query?.searchId;
            },
            ...mapGetters([
                'ratingFields',
                'serviceFields',
            ]),
            ...mapState([
                'submitted',
                'results',
                'count',
                'loading',
                'searchFields'
            ]),
            ...mapState('toolbox', ['permissions'])
        },
        async created() {
            this.SET_SEARCH_FIELDS({
                key: 'page',
                value: this.$route.query.page ? this.$route.query.page : 1
            });

            this.SET_SEARCH_FIELDS({
                key: 'perPage',
                value: this.$route.query.page ? this.$route.query.perPage : 25
            });

            await this.fetchUser();
        },
        methods: {
            async pageChange() {
                await this.submitSearch(false);
                window.scrollTo(0, document.querySelector("body").scrollHeight);
            },
            onRowSelected(items) {
                if (items[0] && this.$route.name.includes('service')) {
                    this.SET_DATA({
                        type: 'selectedResult',
                        data: {
                            id: items[0].id,
                            company: items[0].company,
                            date: items[0].date,
                            payload: items[0].payload,
                            response: items[0].response,
                            jsonResponse: items[0].jsonResponse,
                            service: items[0].service,
                            vendor: items[0].vendor,
                            requestDetails: items[0].requestDetails,
                        },
                    });

                    this.$router.push({
                        name: 'service-details',
                        params: {'serviceId': items[0].uuid, 'previousUrl': this.$router.currentRoute},
                    });
                } else if (items[0] && this.$route.name.includes('rating')) {
                    this.SET_DATA({
                        type: 'selectedResult',
                        data: {
                            id: items[0].id,
                            extension: items[0].extension,
                            createdAt: items[0].createdAt,
                            requestEndedAt: items[0].requestEndedAt,
                            requestStartedAt: items[0].requestStartedAt,
                            payload: items[0].payload,
                            policySystem: items[0].policySystem,
                            jsonResponse: items[0].jsonResponse,
                        }
                    });

                    this.$router.push({
                        name: 'rating-details',
                        params: {'ratingId': items[0].id, 'previousUrl': this.$router.currentRoute},
                    });
                }
            },
            returnToFirst() {
                this.currentPage = 1;
            },
            ...toolboxFilters.filters,
            ...mapActions('toolbox', ['fetchUser']),
            ...mapMutations(['SET_DATA'])
        },
    };
</script>
